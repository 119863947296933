// commit 49f1a7e 2019-09-19

@import '../tdi/css/lib';

a {cursor:pointer;}
strong {font-weight:bold;}
em {font-style:italic;}
sup, sub {position: relative;top: -0.4em;vertical-align: baseline;}
sub { top: 0.4em; }
tr {height:20px;}
section img {max-width:100%;height:auto;}

.marginbottom- {
  &none { margin-bottom:0; }
}
span.highlight {
  background-color:#d6ecff;
}

@each $list-style in (
  none, square, circle, disc,                                                       // ul list styles
  decimal, decimal-leading-zero, lower-alpha, upper-alpha, lower-roman, upper-roman // ol list-styles
) {
  .list-style-#{$list-style} {
    list-style: $list-style;
  }
}
